import { useEffect, useState } from "react";
import marissa from '../../assets/images/marissa.png'
import aaron from '../../assets/images/aaron.png'
import iris from '../../assets/images/iris.png'
import samuel from '../../assets/images/samuel.png'
import dickson from '../../assets/images/dickson.png'
import plane from '../../assets/images/plane.gif'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import BalanceIcon from '@mui/icons-material/Balance';
import Dialog from '@mui/material/Dialog';
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en-gb";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './WithPeople.css'

function WithPeople(props:any){

    const group = ['Dickson','Iris','Samuel','Marissa','Aaron']
    const destination = 'Toronto'

    const [totalAmount, setTotalAmount] = useState(0)
    const [eachPersonBalance, setEachPersonBalance] = useState([0,0,0,0,0])

    const [listOfTransactions, setListOfTransactions] = useState([] as any)

    const [balances, setBalances] = useState({} as any)
    const [highestBalance, setHighestBalance] = useState(0)

    const [page, setPage] = useState(0)

    const [newExpenseState, setNewExpenseState] = useState(false)

    const handleClose = () => {
        setNewExpenseState(false)
    };

    useEffect(()=>{
        // setListOfTransactions(transformTransactions(sampleTransactionList))
        let req = new XMLHttpRequest();

        req.onreadystatechange = () => {
          if (req.readyState == XMLHttpRequest.DONE) {
            console.log(req.responseText);
            setListOfTransactions(transformTransactions(JSON.parse(req.responseText)['record']['data']));
          }
        };
        
        req.open("GET", "https://api.jsonbin.io/v3/b/66a0c788acd3cb34a86a8fc2", true);
        req.setRequestHeader("X-Master-Key", "$2a$10$/wE7k7rDoR8Vw/36t76BWeXVSS8dhQKEn0KaKvdZeBBgWBFqaQxOq");
        req.send();

        // json silo
        // let req = new XMLHttpRequest() as any;
        // req.onreadystatechange = () => {
        // if (req.readyState == XMLHttpRequest.DONE) {
            
        //     setListOfTransactions(transformTransactions(JSON.parse(req.responseText)['data']));
        // }
        // };

        // req.open("GET", "https://api.jsonsilo.com/df87ed87-5eed-422a-b016-7f58ffdd2f92", true);
        // req.setRequestHeader("X-Silo-Key", "gEZOsOhkJ0dl97L6G2y4ossdjJT7YSxMFJg0fCQrwA");
        // req.setRequestHeader('Content-Type', 'application/json')
        // req.send();



        // forgot
        // let req = new XMLHttpRequest() as any;
        // req.onreadystatechange = () => {
        // if (req.readyState == XMLHttpRequest.DONE) {
        //     console.log(req.responseText)
        // }
        // };

        // req.open("GET", "https://api.jsonsilo.com/api/v1/manage/df87ed87-5eed-422a-b016-7f58ffdd2f92", true);
        // req.setRequestHeader("X-MAN-API", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3V1aWQiOiI4cGY0eXM3RVI4TkVqNTdwcEtDTnRBU0dwZkIzIiwiaXNzIjoiaHR0cHM6Ly9qc29uc2lsby5jb20iLCJleHAiOjE3MjQ0MTExNTB9.5zjD3g8kQy-POgvUQq25KlTlZHW6hM3j4i47NDYkqJw");
        // req.setRequestHeader('Content-Type', 'application/json')
        // req.setRequestHeader('Accept', 'application/json')
        // req.send();
        // setListOfTransactions(sampleTransactionList)
    },[])

    function transformTransactions(transactions: any[]): any[][] {
        const groupedTransactions: { [key: string]: any[] } = {};
    
        // Parse the date strings into Date objects for comparison and sort transactions by date in descending order
        transactions.sort((a, b) => {
            const dateA = parseDate(a.date);
            const dateB = parseDate(b.date);
            return dateB.getTime() - dateA.getTime();
        });
    
        // Group transactions by date
        transactions.forEach(transaction => {
            const date = transaction.date;
            if (!groupedTransactions[date]) {
                groupedTransactions[date] = [];
            }
            groupedTransactions[date].push(transaction);
        });
    
        // Convert the grouped transactions into the desired format
        const result = Object.keys(groupedTransactions)
            .sort((a, b) => {
                const dateA = parseDate(a);
                const dateB = parseDate(b);
                return dateB.getTime() - dateA.getTime();
            })
            .map(date => groupedTransactions[date]);
    
        return result;
    }
    
    function parseDate(dateStr: string): Date {
        const [day, month, year] = dateStr.split('/').map(Number);
        return new Date(year, month - 1, day);
    }

    // function transformTransactions(transactions:any) {
    //     const groupedTransactions = {} as any;
    
    //     // Group transactions by date
    //     transactions.forEach((transaction:any) => {
    //         const date = transaction.date;
    //         if (!groupedTransactions[date]) {
    //             groupedTransactions[date] = [];
    //         }
    //         groupedTransactions[date].push(transaction);
    //     });
    
    //     // Convert the grouped transactions into the desired format
    //     const result = Object.keys(groupedTransactions).map(date => groupedTransactions[date]);
    
    //     return result;
    // }

    function calculateBalances(transactions:any) {
        const balances = {} as any;
    
        transactions.flat().forEach((transaction:any) => {
            const { payee, splitType, involved, customValue, amount } = transaction;
            const amt = parseFloat(amount);
    
            if (!balances[payee]) {
                balances[payee] = 0;
            }
    
            if (splitType === 'share') {
                const share = amt / involved.length;
                involved.forEach((person:any) => {
                    if (!balances[person]) {
                        balances[person] = 0;
                    }
                    balances[person] -= share;  // Each involved person owes their share
                });
                balances[payee] += amt;  // The payee should get the total amount
            } else if (splitType === 'custom') {
                involved.forEach((person:any, index:number) => {
                    const customAmt = parseFloat(customValue[index]);
                    if (!balances[person]) {
                        balances[person] = 0;
                    }
                    balances[person] -= customAmt;  // Each involved person owes their custom amount
                });
                balances[payee] += amt;  // The payee should get the total amount
            }
        });
        let maxNumber = 0;
        for (const person in balances) {
            if (Math.abs(balances[person]) > maxNumber) {
                maxNumber = Math.abs(balances[person]);
            }
        }
        console.log(maxNumber)
        return [balances,maxNumber];
    }

    useEffect(()=>{
        const output = calculateBalances(listOfTransactions)
        setBalances(output[0])
        setHighestBalance(output[1])
        console.log(output)
    },[listOfTransactions])

    const [newExpenseCategory, setNewExpenseCategory] = useState('')
    const [newExpenseAmount, setNewExpenseAmount] = useState(0.00)
    const [newExpenseDate, setNewExpenseDate] = useState(dayjs())
    const [newExpensePayer, setNewExpensePayer] = useState('')
    const [newExpenseRemarks, setNewExpenseRemarks] = useState('')
    const [newDickson, setNewDickson] = useState(true)
    const [newIris, setNewIris] = useState(true)
    const [newSamuel, setNewSamuel] = useState(true)
    const [newMarissa, setNewMarissa] = useState(true)
    const [newAaron, setNewAaron] = useState(true)
    // const [newExpenseInvolved, setNewExpenseInvolved] = useState([true,true,true,true,true])

    // useEffect(()=>{
    //     console.log(newExpenseInvolved)
    // },[newExpenseInvolved])

    const onHandleCheckboxInvolved = (index:number) => {
        if (index===0){
            setNewDickson(!newDickson)
        } else if (index===1){
            setNewIris(!newIris)
        } else if (index===2){
            setNewSamuel(!newSamuel)
        } else if (index===3){
            setNewMarissa(!newMarissa)
        } else if (index===4){
            setNewAaron(!newAaron)
        }
         
    }

    const onClickCategory = (type:string) => {
        setNewExpenseCategory(type)
    }

    const onChangeTextField = (event:any) => {
        setNewExpenseAmount(event.target.value)
    }

    const onChangeRemarks = (event:any) => {
        setNewExpenseRemarks(event.target.value)
    }

    const onClickSubmitNewExpense = () => {
        let tempInvolved = []
        if (newDickson) tempInvolved.push('Dickson')
        if (newIris) tempInvolved.push('Iris')
        if (newSamuel) tempInvolved.push('Samuel')
        if (newMarissa) tempInvolved.push('Marissa')
        if (newAaron) tempInvolved.push('Aaron')
        let now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        let second = now.getSeconds();
        let newExpenseTime = `${hour}:${minute}:${second}`

        let newTransaction = {
            'payee':newExpensePayer,
            'splitType':'share', // share, custom
            'involved':tempInvolved,
            'customValue':[],
            'currency':'CAD',
            'amount':newExpenseAmount,
            'spendingType':newExpenseCategory, // Food, Living, Attraction, Travel, Driving, Whatever
            'date':newExpenseDate.format('DD/MM/YYYY'),
            'time':newExpenseTime,
            'remarks':newExpenseRemarks
        }
        console.log(newTransaction)
        let newFinalTransaction = listOfTransactions.flat()
        newFinalTransaction.push(newTransaction)
        let finalSubmission = {
            "data":newFinalTransaction
        }
        console.log(finalSubmission)
        let req = new XMLHttpRequest();

        req.onreadystatechange = () => {
        if (req.readyState == XMLHttpRequest.DONE) {
            console.log(req.responseText);
            handleClose();
        }
        };

        req.open("PUT", `https://api.jsonbin.io/v3/b/66a0c788acd3cb34a86a8fc2?${(Math.random() * 1e16).toString(16)}`, true);
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("X-Master-Key", "$2a$10$/wE7k7rDoR8Vw/36t76BWeXVSS8dhQKEn0KaKvdZeBBgWBFqaQxOq");
        req.setRequestHeader("Cache-Control","no-cache, no-store, must-revalidate");
        req.setRequestHeader("Pragma","no-cache");
        req.setRequestHeader("Expires","0");
        req.send(JSON.stringify(finalSubmission));
        // console.log(JSON.stringify(finalSubmission))
        // req.send('{"sample": "Hello World"}');

        //json silo
        // let req = new XMLHttpRequest() as any;
        // req.onreadystatechange = () => {
        // if (req.readyState == XMLHttpRequest.DONE) {
        //     console.log(req.responseText)
        // }
        // };

        // req.open("PATCH", "https://api.jsonsilo.com/api/v1/manage/df87ed87-5eed-422a-b016-7f58ffdd2f92", true);
        // req.setRequestHeader("X-MAN-API", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3V1aWQiOiI4cGY0eXM3RVI4TkVqNTdwcEtDTnRBU0dwZkIzIiwiaXNzIjoiaHR0cHM6Ly9qc29uc2lsby5jb20iLCJleHAiOjE3MjQ0MTExNTB9.5zjD3g8kQy-POgvUQq25KlTlZHW6hM3j4i47NDYkqJw");
        // req.setRequestHeader('Content-Type', 'application/json')
        // req.setRequestHeader('Accept', 'application/json')
        // req.setRequestHeader('Access-Control-Allow-Origin','*')
        // req.setRequestHeader('Access-Control-Allow-Methods','*')
        // req.send(JSON.stringify(finalSubmission));
    }

    return <div className="WithPeopleMain column">
        <div className="topBlock">
            <div><img src={plane} style={{height:80, objectFit:'contain'}} alt="" /></div>
            <div className="listOfAvatar row">
            {
                group.map((e:any, index:number)=>{
                    return <div className="avatar" style={{marginLeft:index!==0?-15:0}}>
                        <img src={namePhotoMap[index]} style={{objectFit:'cover',width:48,height:48,borderRadius:24}} alt="" />
                    </div>
                })
            }
            </div>
        </div>
        <div className="column" style={{padding:'0 24px', marginTop:42}}>
            <div style={{width:'100%',textAlign:'center',fontSize:'1.4em',marginBottom:24}}>Toronto 2024</div>
            <div onClick={()=>setNewExpenseState(true)} style={{padding:'8px 16px',cursor:'pointer',marginBottom: 16, background:'#306fde',borderRadius:24,textAlign:'center',color:'white',fontSize:'0.6em'}}>Add an expense</div>
            <BottomNavigation
                showLabels
                value={page}
                onChange={(event, newValue) => {
                    setPage(newValue);
                }}
                >
                <BottomNavigationAction label="Transaction" icon={<LocalAtmIcon />} />
                <BottomNavigationAction label="Balance" icon={<BalanceIcon />} />
            </BottomNavigation>
            {
                page===0?<div className="column" style={{marginTop:16,overflow:'auto'}}>
                    {
                        listOfTransactions.map((transactionDate:any,transactionDateIndex:number)=>{
                            return <div style={{marginBottom: 16}} className="column" >
                                <div style={{fontSize:'0.6em',color:"#306fde",marginBottom:8}}>{transactionDate[0].date}</div>
                                {
                                    transactionDate.map((transaction:any, transactionIndex:number)=>{
                                        return <div className="column" style={{marginBottom:24}}>
                                            <div className="row alignC">
                                                <div className="row alignC" style={{flex:1}}>
                                                    <img src={namePhotoDict[transaction.payee]} style={{height:48,width:48,zIndex:9,borderRadius:24,objectFit:'cover'}} alt="" />
                                                    <div className="row alignC" style={{marginLeft:-8,padding:'0px 16px',background:'#efefef',borderRadius:24,height:36}}>
                                                        <div style={{}}>{categoryEmojiMap[transaction.spendingType]}</div>
                                                        <div style={{marginLeft:8,color:'#7c7c7c',fontSize:'0.6em'}}>{transaction.spendingType}</div>
                                                    </div>
                                                </div>
                                                <b>${transaction.amount}</b>
                                            </div>
                                            <div className="row" style={{alignItems:'end'}}>
                                                <div className="column" style={{fontSize:'0.6em',flex:1}}>
                                                    <div style={{marginTop:8,marginBottom:8}}>{transaction.payee}</div>
                                                    <div>{transaction.remarks}</div>
                                                    <div style={{color:'#b4b4b6',marginTop:transaction.remarks.length===0?0:8}}>{transaction.time}</div>
                                                </div>
                                                <div className="row alignC">
                                                    {
                                                        transaction.involved.map((involvedPeople:any,involvedIndex:number)=>{
                                                            return <img src={namePhotoDict[involvedPeople]} style={{marginRight:involvedIndex===transaction.involved.length-1?0:-12,height:36,width:36,borderRadius:24,objectFit:'contain'}} alt="" />                                                        
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                </div>:<div className="column" style={{marginTop:16,paddingTop:16,overflow:'auto'}}>
                    {balances&&group.map((balancePeople:any,balanceIndex:number)=>{
                        if(balances[balancePeople]>=0){
                            return <div className="row alignC" style={{marginBottom:16}}>
                                <div style={{position:'relative',width:'calc(50% - 12px)',background:'#fdf8f4',height:48,borderTopLeftRadius:8,borderBottomLeftRadius:8}}>
                                    <div style={{position:'absolute',width:`${Math.abs(balances[balancePeople])*100/highestBalance}%`,background:'#b6d5d8',height:48,right:0,top:'50%',transform:'translateY(-50%)'}}></div>
                                    <b style={{position:'absolute',height:48,right:8,top:'50%',transform:'translateY(-50%)',display:'flex',alignItems:'center'}}>${balances[balancePeople].toFixed(2)}</b>
                                </div>
                                <div style={{width:24}}></div>
                                <div className="row alignC" style={{width:'calc(50% - 12px)'}}>
                                    <img src={namePhotoDict[balancePeople]} style={{height:36,width:36,borderRadius:24,objectFit:'contain'}} alt="" />                                                        
                                    <div style={{height:48,display:'flex',alignItems:'center',fontSize:'0.8em',marginLeft:8}}>{balancePeople}</div>
                                </div>
                            </div>
                        } else {
                            return <div className="row alignC" style={{marginBottom:16}}>
                                <div className="row alignC" style={{width:'calc(50% - 12px)',justifyContent:'end'}}>
                                    <img src={namePhotoDict[balancePeople]} style={{height:36,width:36,borderRadius:24,objectFit:'contain'}} alt="" />                                                        
                                    <div style={{height:48,display:'flex',alignItems:'center',fontSize:'0.8em',marginLeft:8}}>{balancePeople}</div>
                                </div>
                                <div style={{width:24}}></div>
                                <div style={{position:'relative',width:'calc(50% - 12px)',background:'#fdf8f4',height:48,borderTopLeftRadius:8,borderBottomLeftRadius:8}}>
                                    <div style={{position:'absolute',width:`${Math.abs(balances[balancePeople])*100/highestBalance}%`,background:'#f2b9aa',height:48,left:0,top:'50%',transform:'translateY(-50%)'}}></div>
                                    <b style={{position:'absolute',height:48,left:8,top:'50%',transform:'translateY(-50%)',display:'flex',alignItems:'center'}}>${Math.abs(balances[balancePeople]).toFixed(2)}</b>
                                </div>
                                
                            </div>
                        }
                    })}
                </div>
            }
        </div>
        <Dialog onClose={handleClose} open={newExpenseState}>
            <div style={{height:'80svh',width:'84svw',padding:'24px',boxSizing:'border-box'}} className="column">
                <div style={{fontSize:'1.2em',fontWeight:600}}>Create new expense</div>
                <div style={{fontSize:'1em',fontWeight:400,marginTop:24,marginBottom:12}}>Category</div>
                <div style={{display:'flex',flexWrap:'wrap',justifyContent:'flex-start'}}>
                    {
                        allCategories.map((category:any,categoryIndex:number)=>{
                            return <div onClick={()=>onClickCategory(category)} className="row alignC" style={{margin:'4px 2px',padding:'0px 16px',background:newExpenseCategory===category?'#dadada':'#efefef',borderRadius:24,height:36}}>
                                <div style={{}}>{categoryEmojiMap[category]}</div>
                                <div style={{marginLeft:8,color:'#7c7c7c',fontSize:'0.6em',fontWeight:newExpenseCategory===category?'600':'400'}}>{category}</div>
                            </div>
                        })
                    }
                </div>
                <div style={{fontSize:'1em',fontWeight:400,marginTop:24,marginBottom:12}}>Total Amount</div>
                <div className="alignE" style={{}}>
                    <TextField
                        value={newExpenseAmount}
                        onChange={onChangeTextField}
                    />
                    <div style={{marginLeft:8}}>CAD</div>
                </div>
                <div style={{display:'flex',flexDirection:'row-reverse'}} >
                    <div className="column" style={{width:'48%',}}>
                        <div style={{fontSize:'1em',fontWeight:400,marginTop:24,marginBottom:12}}>Involved</div>
                        <div className="row alignC">
                            <Checkbox
                                checked={newDickson}
                                onChange={()=>onHandleCheckboxInvolved(0)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <div onClick={()=>onHandleCheckboxInvolved(0)}>Dickson</div>
                        </div>
                        <div className="row alignC">
                            <Checkbox
                                checked={newIris}
                                onChange={()=>onHandleCheckboxInvolved(1)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <div onClick={()=>onHandleCheckboxInvolved(1)}>Iris</div>
                        </div>
                        <div className="row alignC">
                            <Checkbox
                                checked={newSamuel}
                                onChange={()=>onHandleCheckboxInvolved(2)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <div onClick={()=>onHandleCheckboxInvolved(2)}>Samuel</div>
                        </div>
                        <div className="row alignC">
                            <Checkbox
                                checked={newMarissa}
                                onChange={()=>onHandleCheckboxInvolved(3)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <div onClick={()=>onHandleCheckboxInvolved(3)}>Marissa</div>
                        </div>
                        <div className="row alignC">
                            <Checkbox
                                checked={newAaron}
                                onChange={()=>onHandleCheckboxInvolved(4)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <div onClick={()=>onHandleCheckboxInvolved(4)}>Aaron</div>
                        </div>
                    </div>
                    <div style={{width:'4%'}}></div>
                    <div className="column " style={{width:'48%'}}>
                        <div className="column" style={{}}>
                            <div style={{fontSize:'1em',fontWeight:400,marginTop:24,marginBottom:12}}>Payer</div>
                            <div className="row alignC">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={newExpensePayer}
                                label="Age"
                                onChange={(event:any)=>setNewExpensePayer(event.target.value)}
                                >
                                    <MenuItem value={'Dickson'}><div style={{display:'flex',alignItems:'center'}}><img src={namePhotoDict['Dickson']} style={{height:24,width:24,borderRadius:24,objectFit:'contain',marginRight:8}} alt="" />Dickson</div></MenuItem>
                                    <MenuItem value={'Iris'}><div style={{display:'flex',alignItems:'center'}}><img src={namePhotoDict['Iris']} style={{height:24,width:24,borderRadius:24,objectFit:'contain',marginRight:8}} alt="" />Iris</div></MenuItem>
                                    <MenuItem value={'Samuel'}><div style={{display:'flex',alignItems:'center'}}><img src={namePhotoDict['Samuel']} style={{height:24,width:24,borderRadius:24,objectFit:'contain',marginRight:8}} alt="" />Samuel</div></MenuItem>
                                    <MenuItem value={'Marissa'}><div style={{display:'flex',alignItems:'center'}}><img src={namePhotoDict['Marissa']} style={{height:24,width:24,borderRadius:24,objectFit:'contain',marginRight:8}} alt="" />Marissa</div></MenuItem>
                                    <MenuItem value={'Aaron'}><div style={{display:'flex',alignItems:'center'}}><img src={namePhotoDict['Aaron']} style={{height:24,width:24,borderRadius:24,objectFit:'contain',marginRight:8}} alt="" />Aaron</div></MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="column" style={{width:'100%'}}>
                            <div style={{fontSize:'1em',fontWeight:400,marginTop:24,marginBottom:12}}>Date</div>
                            <div className="row alignC">
                                <div style={{width:'100%'}}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="en-gb"
                                    >
                                        <DatePicker
                                            value={newExpenseDate}
                                            onChange={(newValue:any) => setNewExpenseDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{fontSize:'1em',fontWeight:400,marginTop:0,marginBottom:12}}>Remarks</div>
                <TextField
                    value={newExpenseRemarks}
                    onChange={onChangeRemarks}
                />
                <div style={{width:'100%',display:'flex',justifyContent:'end',marginTop:16}}>
                    <div onClick={onClickSubmitNewExpense} style={{padding:'16px 24px',cursor:'pointer', background:'#306fde',borderRadius:24,textAlign:'center',color:'white',fontSize:'0.8em'}}>Submit</div>
                </div>
            </div>
        </Dialog>
    </div>
}

const sampleTransactionList = [
        {
            'payee':'Aaron',
            'splitType':'share',
            'involved':['Dickson','Iris','Samuel','Marissa','Aaron'],
            'customValue':[],
            'currency':'CAD',
            'amount':'1154',
            'spendingType':'Driving',
            'date':'25/07/2024',
            'time':'15:30:12',
            'remarks':''
        },
        {
            'payee':'Dickson',
            'splitType':'share', 
            'involved':['Dickson','Iris','Samuel','Marissa','Aaron'],
            'customValue':[],
            'currency':'CAD',
            'amount':'433.3',
            'spendingType':'Attraction',
            'date':'23/07/2024',
            'time':'19:55:12',
            'remarks':'Flowerpot Island Boat Tour'
        },
    
        {
            'payee':'Dickson',
            'splitType':'share', 
            'involved':['Dickson','Iris','Samuel','Marissa','Aaron'],
            'customValue':[],
            'currency':'CAD',
            'amount':'100',
            'spendingType':'Food',
            'date':'23/07/2024',
            'time':'16:30:12',
            'remarks':'Niagara Fall Dinner Reservation Fee'
        },
        {
            'payee':'Samuel',
            'splitType':'share', 
            'involved':['Dickson','Iris','Samuel','Marissa','Aaron'],
            'customValue':[],
            'currency':'CAD',
            'amount':'401.15',
            'spendingType':'Attraction',
            'date':'21/07/2024',
            'time':'12:30:11',
            'remarks':'Blue Mountain One Day Pass'
        }
]

const nameEmojiMap = ["👻","🐨","🐮","🐶","🐥"]
const allCategories = ['Food', 'Living', 'Attraction', 'Driving', 'Whatever']
const categoryEmojiMap = {'Food':"🍔",'Living':"🛌",'Attraction':"🏖️",'Driving':"🚗",'Whatever':"🥳"} as any

const namePhotoMap = [dickson,iris,samuel,marissa,aaron]
const namePhotoDict = {'Dickson':dickson,'Iris':iris,'Samuel':samuel,'Marissa':marissa,'Aaron':aaron} as any

const colorEmojiMap = ['red','blue','green','yellow','orange']

export default WithPeople;