import { useEffect, useState } from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import ExpenseData from './data.json';

function Expenses(props:any){

    const [data, setData] = useState([] as any)

    const [staticData, setStaticData] = useState([] as any)
    const [filterType, setFilterType] = useState('')
    
    const onClickGraph = (type:number) => {
        const itemType = ['Food','Travel','Entertainment','Health','Utilities','Shopping']
        setFilterType(itemType[type])
    }

    useEffect(()=>{
        let tempData = ExpenseData['data'].filter((item:any)=>{
            return item['Category']===filterType
        })
        setStaticData(tempData)
    },[filterType])

    useEffect(()=>{
        let tempData = ExpenseData['data'] as any
        // for (let i=0; i<tempData.length; i++){
        //     tempData[i]['value'] = parseFloat(tempData[i]['Amount'])
        //     tempData[i]['id'] = i
        //     tempData[i]['label'] = tempData[i]['Category']
        // }
        console.log(tempData)
        let categories = {'Food':0,'Travel':0,'Entertainment':0,'Health':0,'Utilities':0,'Shopping':0} as any
        for (let i=0; i<tempData.length; i++){
            if (tempData[i]['Currency Type']!=='HKD'){
                categories[tempData[i]['Category']] += parseFloat(tempData[i]['Amount'])*parseFloat(tempData[i]['Currency Rate'])
            } else {
                categories[tempData[i]['Category']] += parseFloat(tempData[i]['Amount'])
            }
        }
        let tempData2 = [] as any
        for (let key in categories){
            tempData2.push({'label':key,'value':categories[key]})
        }
        for (let i=0; i<tempData2.length; i++){
            tempData2[i]['id'] = i
        }
        setData(tempData2)
        setStaticData(ExpenseData.data)
    },[])

    return <div className="column">
         <PieChart
            series={[
                {
                data: data,
                },
            ]}
            onItemClick={(point, d) => {
                onClickGraph(d.dataIndex)
                console.log(d)
            }}
            width={400}
            height={200}
            />
        <div className="column">
            {
                staticData.map((item:any)=>{
                    return <div className="row">
                            <div style={{marginRight: 8, width:100}}>{item['Date']}</div>
                            <div style={{marginRight: 8, width:200}}>{item['Category']}</div>
                            <div style={{marginRight: 8, width:100}}>{item['Amount']}</div>
                            <div style={{marginRight: 8, width:200, wordBreak:'break-all'}}>{item['Description']}</div>
                            <div style={{marginRight: 8, width:100}}>{item['Currency Type']}</div>
                        </div>
                })
            }
        </div>
    </div>
}

export default Expenses;