import { useEffect, useState, useRef } from 'react'
import questionbank from './questionbank'
import moment from "moment";
import {CountUpTimer} from "react-countup-timer";
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import celebrateemoji from '../../assets/images/celebrateemoji.png'
import Crying_thumbs_up_cat from '../../assets/images/Crying_thumbs_up_cat.webp'
import ReactGA from 'react-ga4'
import './CPExam.css'

const TRACKING_ID = "G-BVLZ5W194D"
ReactGA.initialize(TRACKING_ID)

function CPExam(props: any) {
    // console.log(questionbank)
    
    ReactGA.send({
        hitType: 'pageview',
        page:'/cpexam',
        title:'Cloud Practitioner Practice'
    })

    const [currentState, setCurrentState] = useState('start')
    const [score, setScore] = useState(0)
    const [totalQAsked, setTotalQAsked] = useState(0)
    const [questionBankNum, setQuestionBankNum] = useState(0)
    const [currentQ, setCurrentQ] = useState('')
    const [currentA, setCurrentA] = useState('')
    const [currentB, setCurrentB] = useState('')
    const [currentC, setCurrentC] = useState('')
    const [currentD, setCurrentD] = useState('')
    const [currentSelection, setCurrentSelection] = useState('')
    const [currentAnswer, setCurrentAnswer] = useState('')
    const [timer, setTimer] = useState(0);
    const [startTimer, setStartTimer] = useState(false);

    const startExam = () => {
        setCurrentState('exam')
        setTotalQAsked(1)
    }
    
    const startCountDown = () => {
        setCurrentState('countdown')
        setStartTimer(true)
        setTotalQAsked(1)
        setTimer(60)
    }

    const handleRadioChange = (event: any) => {
        // console.log(event)
        setCurrentSelection(event.target.value);
    };

    const renderQuestions = () => {
        let questionNum;
        do {questionNum = Math.floor(Math.random() * questionbank.length);} 
        while (questionbank[questionNum].answer.length>1);
        setQuestionBankNum(questionNum)
        setCurrentQ(questionbank[questionNum].question)
        setCurrentA(questionbank[questionNum].A)
        setCurrentB(questionbank[questionNum].B)
        setCurrentC(questionbank[questionNum].C)
        setCurrentD(questionbank[questionNum].D)
    }

    const checkAnswer = () => {
        if(currentSelection === questionbank[questionBankNum].answer){
            setScore(score+1)
            setCurrentAnswer("")
            setCurrentSelection("")
            setTotalQAsked(totalQAsked+1)
        } else {
            setCurrentAnswer(questionbank[questionBankNum].answer)
            setTimeout(() => {
                setCurrentAnswer("")
                setCurrentSelection("")
                setTotalQAsked(totalQAsked+1)
            }, 1500);
        }
    }

    const onBackHome = () => {
        setTotalQAsked(0)
        setScore(0)
        setStartTimer(false)
        setCurrentState('start')
    }

    useEffect(()=>{
        renderQuestions()
        console.log(score)
    },[totalQAsked])

    const increment = useRef(null) as any;
    useEffect(() => {
        if (increment&&startTimer){
            increment.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 1) return timer - 1;
                else {
                    clearInterval(increment.current);
                    setStartTimer(false)
                    setCurrentState('countdowncomplete')
                    return timer;
                }
            });
            }, 1000);
        }
    }, [startTimer]);

    const handleReset = () => {
        clearInterval(increment.current);
        setTimer(0);
    };

    return (
        <div className='CPExam' style={{width: '100%', height: '100%', minHeight:currentState!=='start'?'100%':'70vh',boxSizing:'border-box'}}>
            {
                currentState==='start'?<div className="column alignC justifyC" style={{ width: '100%', height: '100%',minHeight:'70vh' }}>
                    <h1 style={{textAlign:'center'}}>Cloud Practitioner Practice</h1>
                    <div className='row alignC' >
                        <div
                            style={{ marginRight: 24}}
                            className="text-white playMode"
                            onClick={startExam}
                        >
                            Unlimited Questions
                        </div>
                        <div
                            style={{  }}
                            className="text-white playMode"
                            onClick={startCountDown}
                        >
                            Count Down Run
                        </div>
                    </div>
                </div>:
                currentState==='exam'?<div className='column justifyC' style={{width:'100%',padding:32,boxSizing:'border-box',height:'100vh',justifyContent:'space-between'}} >
                    <div className='column' style={{justifyContent:'space-between'}}>
                        <div style={{position:'relative',display:'flex',width:'100%'}}>
                            <div style={{width:'100%',height:30,borderRadius:30,}} className='timebar'></div>
                            <div style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)',fontSize:'0.8em',color:'#FFF'}}><CountUpTimer startTime={moment().unix()}/></div>
                        </div>
                        <div className='row alignC' style={{justifyContent:'space-between',marginTop:32,marginBottom:16}}>
                            <div className='text-gray' style={{fontSize:'1.2em'}}>Question {totalQAsked}</div>
                            {totalQAsked>1&&<div style={{fontSize:'0.6em',color:'#e198ac'}}>{score}/{totalQAsked-1} Correct | {(score*100/(totalQAsked-1)).toFixed(0)}%</div>}
                        </div>
                        <Divider flexItem aria-hidden="true"/>
                        <div className='questionMain column' style={{flex:1,marginTop:16}}>
                            
                            <div className='questionBank question'>{currentQ}</div>
                            <div style={{background:currentAnswer==='A'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'A'}})}>
                                <div className=' '>A. {currentA}</div>
                                <Radio
                                    checked={currentSelection === 'A'}
                                    onChange={handleRadioChange}
                                    value="A"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                            </div>
                            <div style={{background:currentAnswer==='B'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'B'}})}>
                                <div className=' '>B. {currentB}</div>
                                <Radio
                                    checked={currentSelection === 'B'}
                                    onChange={handleRadioChange}
                                    value="B"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                            </div>
                            <div style={{background:currentAnswer==='C'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'C'}})}>
                                <div className=' '>C. {currentC}</div>
                                <Radio
                                    checked={currentSelection === 'C'}
                                    onChange={handleRadioChange}
                                    value="C"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'C' }}
                                />
                            </div>
                            <div style={{background:currentAnswer==='D'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'D'}})}>
                                <div className=' '>D. {currentD}</div>
                                <Radio
                                    checked={currentSelection === 'D'}
                                    onChange={handleRadioChange}
                                    value="D"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'D' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',marginBottom:16, cursor:'pointer', backgroundColor:'#0b7fe6', borderRadius:20,padding:12,boxSizing:'border-box'}} className='justifyC alignC text-white' onClick={checkAnswer}>
                        Next
                    </div>
                </div>:
                currentState==='countdown'?<div className='column justifyC' style={{width:'100%',padding:32,boxSizing:'border-box',height:'100vh',justifyContent:'space-between'}} >
                    <div className='column' style={{justifyContent:'space-between'}}>
                        <div style={{position:'relative',display:'flex',width:'100%'}}>
                            <div style={{width:'100%',height:30,borderRadius:30,position:'absolute',background:'#4d4d4d'}} className='timebarStatic'></div>
                            <div style={{width:`${timer*100/60}%`,height:30,borderRadius:30,zIndex:3}} className='timebar'></div>
                            <div style={{position:'absolute',left:'50%',top:'50%',zIndex:4,transform:'translate(-50%,-51%)',fontSize:'0.8em',color:'#FFF'}}>{timer}</div>
                            <AccessAlarmIcon style={{position:'absolute',zIndex:6,right:6,top:'50%',transform:'translateY(-50%)',color:'white'}} />
                        </div>
                        <div className='row alignC' style={{justifyContent:'space-between',marginTop:32,marginBottom:16}}>
                            <div className='text-gray' style={{fontSize:'1.2em'}}>Question {totalQAsked}</div>
                            {totalQAsked>1&&<div style={{fontSize:'0.6em',color:'#e198ac'}}>{score}/{totalQAsked-1} Correct | {(score*100/(totalQAsked-1)).toFixed(0)}%</div>}
                        </div>
                        <Divider flexItem aria-hidden="true"/>
                        <div className='questionMain column' style={{flex:1,marginTop:16}}>
                            
                            <div className='questionBank question'>{currentQ}</div>
                            <div style={{background:currentAnswer==='A'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'A'}})}>
                                <div className=' '>A. {currentA}</div>
                                <Radio
                                    checked={currentSelection === 'A'}
                                    onChange={handleRadioChange}
                                    value="A"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                            </div>
                            <div style={{background:currentAnswer==='B'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'B'}})}>
                                <div className=' '>B. {currentB}</div>
                                <Radio
                                    checked={currentSelection === 'B'}
                                    onChange={handleRadioChange}
                                    value="B"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                            </div>
                            <div style={{background:currentAnswer==='C'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'C'}})}>
                                <div className=' '>C. {currentC}</div>
                                <Radio
                                    checked={currentSelection === 'C'}
                                    onChange={handleRadioChange}
                                    value="C"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'C' }}
                                />
                            </div>
                            <div style={{background:currentAnswer==='D'?'rgb(50 128 96)':'transparent'}} className='row alignC answer questionBank' onClick={()=>handleRadioChange({"target":{"value":'D'}})}>
                                <div className=' '>D. {currentD}</div>
                                <Radio
                                    checked={currentSelection === 'D'}
                                    onChange={handleRadioChange}
                                    value="D"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'D' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',marginBottom:16, cursor:'pointer', backgroundColor:'#0b7fe6', borderRadius:20,padding:12,boxSizing:'border-box'}} className='justifyC alignC text-white' onClick={checkAnswer}>
                        Next
                    </div>
                </div>:
                currentState==='countdowncomplete'?<div className='column justifyC' style={{width:'100%',padding:32,boxSizing:'border-box',height:'100vh',justifyContent:'space-between'}} >
                    <div className='column' style={{justifyContent:'center',minHeight:'70vh'}}>
                        <div className='text-white' style={{fontSize:'1em',marginBottom:16}}>Result of Your Count Down Test</div>
                        <div style={{background:'white',borderRadius:24,padding:36,textAlign:'center'}} className='column justifyC'>
                            <div style={{fontSize:'1em',fontWeight:400}}>{(score*100/(totalQAsked-1))<50?'Try Again! You have scored':'Congratulations! You have scored'}</div>
                            <div style={{fontSize:'1.8em',fontWeight:600}}>{totalQAsked===1&&score===0?'0':(score*100/(totalQAsked-1)).toFixed(0)}%</div>
                            <div style={{position:'relative',height:24,width:'100%',margin:'16px 0'}}>
                                <div style={{height:24,width:24,borderRadius:12,background:'#272727',position:'absolute',top:0,left:-36-14}}></div>
                                <div style={{width:'calc(100% + 36px + 36px)',borderTop:'1px dashed #272727',position:'absolute',top:'50%',left:-36,transform:'translateY(-50%)'}}></div>
                                <div style={{height:24,width:24,borderRadius:12,background:'#272727',position:'absolute',top:0,right:-36-14}}></div>
                            </div>
                            <div style={{marginTop:16,fontSize:'0.8em'}} className=''>Thank you for playing!</div>
                            <img src={(score*100/(totalQAsked-1))>=50?celebrateemoji:Crying_thumbs_up_cat} style={{height:100,objectFit:'contain',marginTop:16}} alt="" />
                        </div>
                    </div>
                    <div style={{width:'100%',marginBottom:16, cursor:'pointer', backgroundColor:'#0b7fe6', borderRadius:20,padding:12,boxSizing:'border-box'}} className='justifyC alignC text-white' onClick={onBackHome}>
                        Back to Home
                    </div>
                </div>:null
            }
        </div>
    )
}

export default CPExam
