import './LandingPage.css'
import TopBar from '../../components/Topbar';
import MainPage from './MainPage';
import About from '../About/About';

function LandingPage(){
    function createStars(type:any, quantity:any) {
        for (let i = 0; i<quantity; i++) {
            var star = document.createElement('div');
            star.classList.add('star', `type-${type}`);
            star.style.left = `${randomNumber(1, 99)}%`;
            star.style.bottom = `${randomNumber(1, 200)}%`;
            star.style.animationDuration = `${randomNumber(50, 100)}s`;
            document.body.appendChild(star);
        }
    }

   function randomNumber(min:any, max:any) {
        return Math.floor(Math.random() * max) + min;
    }

    return <div className="LandingPage">
        {createStars(1, 200)}
        {createStars(2, 150)}
        {createStars(3, 100)}
        <div style={{height:'100vh'}}></div>
        {/* <TopBar />
        <MainPage />
        <About /> */}
    </div>
}

export default LandingPage;